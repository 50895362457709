import { Directive, Input, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Popover, Tooltip } from 'bootstrap';

@Directive({
  selector: '[appTooltip]',
  standalone: true,
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input({ required: true }) appTooltip!: {
    title: string;
    placement?: 'top' | 'bottom' | 'right' | 'left';
    content?: string;
  };

  tooltip!: Tooltip;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    const { title, content, placement = 'top' } = this.appTooltip;
    if (title && !content) {
      const tooltipTriggerEl = this.el.nativeElement;
      this.tooltip = new Tooltip(tooltipTriggerEl, {
        placement,
        title,
        trigger: 'hover',
      });
    }

    if (title && content) {
      const popoverTriggerEl = this.el.nativeElement;
      new Popover(popoverTriggerEl, {
        placement,
        content,
        title,
        trigger: 'click',
      });
    }
  }

  ngOnDestroy() {
    this.tooltip.hide();
  }
}
